<template>
  <div data-app>
    <template>
      <v-data-table
          :headers="headers"
          :items="users"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :server-items-length="total"
          @update:items-per-page="updateItemPerPage"
          @update:page="updatePage"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách quản trị viên
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" @click="addNewItem">Thêm mới</v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item.authority="{ item }">
          <span v-if="item.authority == 1">Người viết bài</span>
          <span v-else>Quản trị</span>
        </template>

        <template v-slot:item.status="{ item }">
          <v-switch v-model="item.status" :disabled="true"></v-switch>
        </template>


        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>

<!--        <template v-slot:no-results>-->
<!--          <v-alert :value="true" color="error" icon="warning">-->
<!--            Your search found no results.-->
<!--          </v-alert>-->
<!--        </template>-->
      </v-data-table>
    </template>
  </div>
</template>
<script>
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {GET_LIST_USERS} from "@/store/user.module";
import {validationMixin} from "vuelidate";
// import HnrInput from '@/components/elements/hnr-input'
import {SET_LOADING} from "@/store/loading.module";
import {DELETE_USER} from "@/store/user.module";
import {ERROR} from "@/store/alert.module";
import JwtService from "@/common/jwt.service";

export default {
  mixins: [validationMixin],
  components: {
    // HnrInput
  },
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id"
      },
      {text: "Tên đăng nhập", value: "nick_name"},
      {text: "Full name", value: "full_name"},
      {text: "Email", value: "email"},
      {text: "Quyền truy cập", value: "authority"},
      {text: "Trạng thái", value: "status"},
      {text: "Ngày tạo", value: "created_at"},
      {text: "Actions", value: "actions", sortable: false}
    ],
    total: 0,
  }),

  computed: {
    ...mapState({
      users: state => state.user.users
    }),
  },
  watch: {
    users: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.total = newVal.length;
        }
      }
    }
  },
  created() {
    this.authority = JwtService.getAuthority() || 1;
    this.getUsers();
    this.$store.dispatch(SET_BREADCRUMB, [{title: "User", route: "user"}]);
  },
  methods: {
    getUsers() {
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(GET_LIST_USERS).then(() => {
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
      ;
    },


    updateItemPerPage($event) {
      this.itemsPerPage = $event;
      this.getUsers();
    },

    updatePage($event) {
      this.page = $event;
      this.getUsers();
    },

    editItem(item) {
      this.$router.push({name: 'user-detail', params: {userId: item.id}});
    },

    deleteItem(item) {
      const index = this.users.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
      this.$store.dispatch(DELETE_USER, item.id).then((data) => {
        if (data.status) {
          this.users.splice(index, 1);
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {root: true});
          } else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }
        }
      });

    },

    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    addNewItem() {
        this.$router.push({name: 'user-add'});
    }
  }
};
</script>
